import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./swiper1.css";
//import Textplacer1 from './tp1.js';

// import required modules
import { FreeMode, Pagination } from "swiper";

class Swiper1 extends React.Component {
    render(){
        
  return (
      <Swiper
        slidesPerView={3}
        spaceBetween={90}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          10: {
            slidesPerView: 2,
            spaceBetween: 70,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 50,
          },
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper"
      >
        
        {
                this.props.items.map( (item, i) => <SwiperSlide key={i}><div className="Tp1">
                <div className="HeadingTxtSS">{item.name}</div>
                <div className="SubTxtSS">{item.description}</div>
            </div></SwiperSlide> )
            }
      </Swiper>
  );
    }
};
export default Swiper1;