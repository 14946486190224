import './Home.css';
//import Header from './Header.js';
import Launchpage from './Launchpage.js';
import About from './About.js';
import Deatilspage from './Detailspage.js';
//import Projectsdone from './Projectsdone.js';
import Testimonial from './Testimonials.js';
import Blogpage from './Blogs.js';
//import Footer from './Footer.js';

function Home() {
  return (
    <div className="App">
    <Launchpage/>
    <About/>
    <Deatilspage/>
    <Blogpage/>
    <Testimonial/>
    
    </div>
  );
}

export default Home;
