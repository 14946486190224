
import Swiper1 from './swiper1.js';
import Designitems from '../Data/data.js';
import Developitems from '../Data/data1.js';
import Analysisitems from '../Data/data2.js';
import React, {useState} from 'react';
import './Placer.css';

function SwiperPlacer() {
  const [active, setActive] = useState("DesignI");
  return (
    <div className="App">
      <button className='bpstyle ' onClick={() => setActive("DesignI")}>Design</button>
      <button className='bpstyle ' onClick={() => setActive("DevelopI")}>Develop</button>
      <button className='bpstyle ' onClick={() => setActive("AnalysisI")}>Analysis</button>
      {active === "DesignI" && <Swiper1 items={ Designitems }/>}
      {active === "DevelopI" && <Swiper1 items={ Developitems }/>}
      {active === "AnalysisI" && <Swiper1 items={ Analysisitems }/>}
    </div>
  );
}

export default SwiperPlacer;

