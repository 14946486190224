
const Analysisitems = [
    {
        name: "POWER BI",
        description: "Skill: 3/5"
    },
    {
        name: " EXCEL",
        description: "Skill: 4/5"
    },
    {
        name: " WORD",
        description: "Skill: 4/5"
    },
    {
        name: "Manage-ment",
        description: "Skill:  4/5"
    },
    {
        name: "Problem Soving",
        description: "Skill: 4/5"
    }
];


export default Analysisitems;
