import React from 'react';

import './About.css';
import { ReactComponent as Gradientstrip } from '../Images/Gradientstrip.svg';
import CustomButton from '../Elements/custombutton.js';

const About = () => {

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch('Goutham-Krishna-2022-Resume.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Goutham-Krishna-2022-Resume.pdf';
                alink.click();
            })
        })
    }

    return(
        <div className="page">
            <div className="Imagerea">
                <div className="cntr">
                    <div className="strip"><Gradientstrip/></div>
                    <div className="fontstyle1 ">ABOUT</div>
                </div>
            </div>
            <div className="Textarea ">
                <div className="abouttxt">
                I started designing at the age of 15, learning softwares and becoming better at it. Later, when i was introduced to programming in my high school days, I decided to be a Computer Engineer. After graduation an starting my career in web development, i was able to combine my programming and design skills. <br></br>Working in a professional work culture, showed me the importance of Business Analysis, Which urged me to pursue a diploma in IT Business Analysis from Conestoga College, Ontario, Canada. 
        
                </div>
                <div className="aboutbutton" onClick={onButtonClick}><CustomButton Title="MY RESUME" /></div>
            </div>
            
        </div>
    );
};
export default About;