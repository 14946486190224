const Blogitems = [
    {
        id: "1",
        image:"bg1",
        date:"25/8/2022",
        name: "How i made this website",
        author:"Goutham Krishna",
        description: "This is how i learned React and more about MERN.",
        bodytext: "It all stated when with a macbook pro. I was going to finish my ITBA course, and about to look for jobs now. That is when i decided to build a portfolio website. Now when you make a portfolio website there is two things, one you can showcase your works, two your portfolio itself. Yes i believe the project portfolio itself is a beeter way to impress your employer. For me it was the best meathod to learn react js and more of the MERN full stack development. Everything in this site was build from skerch, exept one material ui, ans swiper js. The point of using them was also to show that i know to work with them, as they are widely used.  "
        },
   
    {
        id: "2",
        image:"bg1",
        date:"25/8/2022",
        name: "How do you work from an other persons perspective",
        author:"Goutham Krishna",
        description: "Plan as a designer, Design as a developer, Develop as a client ",
        bodytext:"Working in IT sector is quite different. There are different roles, and most important thing is there is a lot of interactions between these roles frequently like no other sector. Development is again deferent from a production line; everything is a new challenge, switching tasks from roles to another is not that easy.  The challenging part is you might have to send the task to the previous role, for corrections or changes. On the other hand, satisfying your client is yet another challenge. With my previous experience working in a Software development team, I have developed a skill, a skill other could also gain, which could help fasten the development process. I call it -Working from another person’s perspectives. A problem most frontend developers face is finding a way to replicate the design provided by the UI/UX Design team. Similarly, the UI/UX Design team face issue working with BA team. In Full Stack environment, this might not be a big issue. But in a bigger team, this really an issue. This is because employees have limited knowledge about the scoop, and limitations of other roles. Now Imagine if the BA team knows the scoop and limitations of the UI/UX team and the development team, quality of their work will be different. Now the UI/UX will be much comfortable with the plan, on the same time if the UI/UX team knows the scoop and limitation of the development team, the design brought by the design team will be easy for development team to develop. One best example is UI/UX designers designing using MUI Design kit, for the development team. This makes the work easier for development team reducing confusions. The prototype will be helpful convincing the client."
        },
    
];


export default Blogitems;