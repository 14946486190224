import React from 'react';
//import Custombox2 from '/Users/gouthamkrishna/code/pineapplewebsite/mywebsite/src/Components/Elements/Custombox2.js';
import './Testimonials.css';
import Testimonialdata from './../Data/Testimonialdata.js';
//import '../Elements/Custombox2.css';
import greekfreak from './../Images/greek.png';

const Testimonials = () => { 
    return(
        <div className="page">
            <img className="greekphoto" src={greekfreak} alt="Divider" />
            <div className="DpTitle BoldHeadingStyle">
                TESTIMONIALS
            </div>
            <div className="wfull">
                <div className="tocntrveertical" >
                    {
                            Testimonialdata.map( (item, i) => <div className="cbox2">
                            <div className="c2imgdiv RG"></div>
                            <div className="c2boxtxt">{item.statement}</div>
                            <div className="c2boxname">{item.role}, {item.company}</div>
                        </div> )
                    }
                 </div>
            </div>
            
        </div>
    );
};
export default Testimonials;