const Valueline = [
    {
        
        date:"1.",
        title: "Creative",
        description: "I believe my works are more focused on creativity, which stand out from others.",
    },
    {
        
        date:"2.",
        title: "Vision",
        description: "My actions, my work, defines my vision. ",
    },
    {
        
        date:"3.",
        title: "Team Player",
        description: "Speak and Listion, Communicate and appriciate; yourself and others.",
    },
    

    
];


export default Valueline;