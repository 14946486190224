
import React, {useRef} from "react";
import {useState} from 'react';

//import TextField from '@mui/material/TextField';
//import CustomButton from '/Users/gouthamkrishna/code/pineapplewebsite/mywebsite/src/Components/Elements/custombutton.js';
import './Footer.css';
import emailjs from '@emailjs/browser';



const Footer = () => { 
    
    const form = useRef();
    const [Name, settName] = useState('');
    const [Email, setuseremail] = useState('');
    const [Message, setmessage] = useState('');
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_4ufnnfr', 'template_cclqovz', form.current, '88vEDpu16S037F0MH')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });

            settName('');
            setuseremail('');
            setmessage('');
      };

    return(
     
        <div className="page">  
                <div className="DpTitle BoldHeadingStyle">
                <p >GOT A MESSAGE FOR ME ,<br></br> LOVE TO HEAR FROM YOU. </p>
                </div>
                <div className="formplacer">
                <form className="formstyle" ref={form} autoComplete="off" onSubmit={sendEmail}>
                    <label className="displyleft ">Name</label>

                    <input className="displyleft bbs" type="text" name="user_name" value={Name} onChange={event => settName(event.target.value)}/>

                    <label className="displyleft">Email</label>

                    <input className="displyleft bbs" type="email" name="user_email" value={Email} onChange={event => setuseremail(event.target.value)}/>

                    <label  className="displyleft">Message</label>

                    <textarea className="displyleft bbs heightta"  name="message" value={Message} onChange={event => setmessage(event.target.value)}/>

                    <input className="displyleft bstyle GG" type="submit" value="Send" />

                </form>
                </div>
            
                <div className='Blackfooter'>
                    <p className='fotterp'>
                    Mail me : goutham.aravally@gmail.com<br></br>
                    Chat me: pineapplegradient@instagram
                    <br></br>
                    <br></br>
                    Call me: <a class="white" href="tel:+12262011846">+1 226 201 1846</a>
                    <br></br>
                    Waterloo Region, Ontario
                    Canada
                    </p>
                </div>
            
        </div>
       
    );
};
export default Footer;