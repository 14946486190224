const Timeline = [
    {
        id: "1",
        date:"2014-18",
        title: "Bachelors in Computer Science & Engineering",
        description: "Mahatma Gandhi University, India",
    },
    {
        id: "2",
        date:"2014-18",
        title: "Best Outgoing Student",
        description: "Ilahia College of Engineering and Technology",
    },
    {
        id: "3",
        date:"2018-19",
        title: ".NET developer",
        description: "Tech Neurons, Kochi, India",
    },
    {
        id: "4",
        date:"2019-",
        title: "Freelancer- PINEAPPLE GRADIENT",
        description: "Designing, Branding, Digital martketing, Developing ",
    },
    {
        id: "5",
        date:"2021-22",
        title: "Post Graduate Diploma in Information Technology Business Analysis ",
        description: "Conestoga College, Kitchener, Ontario, Canada",
    },

    
];


export default Timeline;