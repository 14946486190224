import './CustomButton.css';
import React from 'react';

class CustomButton extends React.Component { 
        render(){
        return(
            <div className="bstyle GG">{this.props.Title}</div>
        );
        }

}
export default CustomButton;

