
const Designitems = [
    {
        name: "Ps",
        description: "Skill: 4/5"
    },
    {
        name: "Ai",
        description: "Skill: 4/5"
    },
    {
        name: "Xd",
        description: "Skill: 4/5"
    },
    {
        name: "Pr",
        description: "Skill: 3.5/5",
    
    },
    {
        name: "Ae",
        description: "Skill: 3/5",
    
    }
];



export default Designitems;
