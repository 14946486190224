import './Header.css';
import  Logo  from '../Images/logo.png';
//import { ReactComponent as Burger } from '/Users/gouthamkrishna/code/pinapplewebsite/pineapple-website/src/Components/images/burger.svg';

import  React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from "react-router-dom";


const Header = () => {
  const navigate = useNavigate();
    const [state, setState] = React.useState({
        left: false
      });
    
      const toggleDrawer =
        (anchor: 'left', open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
          if (
            event && (event.type === 'keydown') && (((React.KeyboardEvent).key === 'Tab') || ((KeyboardEvent).key === 'Shift'))
          ) {
            return;
          }
    
          setState({ ...state, [anchor]: open });
        };
    
      const list = (anchor: 'left') => (
        <Box
          sx={ {width: 250}}
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => {
                navigate(`/`);
              }}>
              <ListItemText primary={'HOME'} />
            </ListItemButton>
          </ListItem>
          </List>
          <Divider />
          <List>
          <ListItem disablePadding>
          <ListItemButton onClick={() => {
                navigate(`/blogs`);
              }}>
              <ListItemText primary={'BLOGS'} />
            </ListItemButton>
          </ListItem>
          </List>
        </Box>
      );


    return(
        <div>
        <div className="Header">
            <div><img  className="LogoL" src={Logo} alt="My logo"  onClick={toggleDrawer('left', true)}/></div>
        </div>

        <SwipeableDrawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        {list('left')}
      </SwipeableDrawer>
      </div>
        
    );
};
export default Header;