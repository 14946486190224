import React from "react";
import { useParams } from "react-router-dom";
import Blogitems from "../Data/data3.js";
import './Blogpage.css';

function Blogpage() {
  const { id } = useParams();
  
  return (
    <div className="page">
      <div className="Blogsection">
        <div className="Bsh1">{Blogitems[id - 1].name}</div>
        <p className="Bshp1">{Blogitems[id - 1].description} | {Blogitems[id - 1].date}</p>
        <p className="Bshp1">{Blogitems[id - 1].author}</p>
        
        <p className="Bspj">{Blogitems[id - 1].bodytext}</p>
      </div>
    </div>
  );
}

export default Blogpage;