import React from 'react';
import './Launchpage.css';
//import './elements/custombutton';
//import CustomButton from "../Elements/custombutton.js";
import { ReactComponent as Gradientstrip } from '../Images/Gradientstrip.svg';

const Launchpage = () => { 
   
    return(
        <div className="Launch page">
            <div className="LHiGK blk boldf">
                <div className="abs bckstrip"><Gradientstrip/></div>
                <div className="abs"> <div className="hibold">Hi, i am</div>
                    <div className="gkbold">Goutham <br></br>Krishna</div>
                    <div>I am a Developer who can also Design and be a Business Analyst. How cool is that.
                    </div>
                </div>  
            </div>
            <div className="Lsubtitle blk boldf">
                    <div className="Ldda">Design<br></br>Develop<br></br>Analysis</div>
                    <div className="Lddas">
                    Steven Roberts once said "Art without engineering is dreaming; engineering without art is calculating". Well, I'm proud to be an artist and an engineer at the same time.  A Developer, A Designer and An Analyst. Check out more about me, in here.
                    </div>
            </div>
        </div>
    );
};
export default Launchpage;