
const Developitems = [
    {
        name: "HTML",
        description: "Skill: 4/5"
    },
    {
        name: "JAVA",
        description: "Skill: 3/5"
    },
    {
        name: "REACT",
        description: "Skill: 3.5/5"
    },
    {
        name: "NODE",
        description: "Skill: 3/5",
    
    },
    {
        name: ".NET",
        description: "Skill: 3/5"
    }
];



export default Developitems;
