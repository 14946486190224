const Blogpage = () => { 
    return(
        <div className="page">
            <div className="DpTitle BoldHeadingStyle">
                <h1>404</h1><h2> PAGE NOT FOUND</h2>
            </div>
           
        </div>
    );
};
export default Blogpage;