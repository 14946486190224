import './App.css';
import Header from './Pages/Header.js';
import Footer from './Pages/Footer.js';
import Home from './Pages/Home.js';
import Bloghome from './Pages/BlogHome.js';
import Blogpage from './Pages/Blogpage.js';
import ErrorPage from './Pages/ErrorPage.js';
import Sidenav from './Pages/Sidenav.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from '../Components/Elements/ScrollToTop.js';

function App() {
  return (
    
<Router>
<div className="App">
    <Header/>
    <Sidenav/>
    <ScrollToTop>
    <Routes>
    
      <Route path="/" element={<Home />} />
      <Route path="blogs" element={<Bloghome/>} />
      <Route path="blogs/:id" element={<Blogpage/>} />
      <Route path="*" element={<ErrorPage />} />
    
    </Routes>
    </ScrollToTop>
     <Footer/>
    </div>



</Router>

  );
}

export default App;
