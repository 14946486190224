
import './Textplacer.css';
import React from 'react';



class Textplacer extends React.Component{

    render(){
    return(
        <div className="Tp">
            
        
            {
                this.props.items.map( (item, i) => <div className="Tp1" >
                <div className="HeadingTxt1">{item.date}</div>
                <div className="SubTxt1">{item.title}</div>
                <div className="SubTxt2">{item.description}</div>
                </div> )
            }
        
        
        </div>
    );
        }
};
export default Textplacer;