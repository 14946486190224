import Swiper2 from './Swiper2.js';
//import Blogitems from './../Data/data3';
import React from 'react';


function SwiperPlacer2() {
  
  return (
    <div className="App">
     <Swiper2/>
    </div>
  );
}

export default SwiperPlacer2;
