import React from 'react';
import './Detailspage.css';
import Textplacer from "../Elements/Textplacer.js";

import FullPic from "../Images/FullPic.png";
//import CustomButtonPlain from "/Users/gouthamkrishna/code/pineapplewebsite/mywebsite/src/Components/Elements/CustomButtonPlain.js";
import SwiperPlacer from '../Elements/Placer.js';
import Timeline from './../Data/Timeline.js';
import Valueline from './../Data/values.js';

const Detailspage = () => {
    return(
        <div className="page">
            <div className="DpTitle BoldHeadingStyle">
               TO KNOW ME MORE, <br></br>YOU MUST KEEP ON <br></br>READING 
            </div>
            <div className="ages">
               <div className='tpcntr'><Textplacer items={ Timeline }/>
                </div> 
            </div>
            <div className="fullpic">
                <img className="fphoto" src={FullPic} alt="My Full Pic" />
            </div>
            <div className="Skillset">
                <div className='tpcntr'><Textplacer items={ Valueline }/></div>
                
            </div>
            <div className="tp1area">
            <SwiperPlacer></SwiperPlacer>
            </div>
        </div>
    );
};
export default Detailspage;