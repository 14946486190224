import React from 'react';
import './Sidenav.css';
import DribbleLogo from "../Images/dribble.png";
import InstagramLogo from "../Images/instagram.png";
import BehanceLogo from "../Images/behance.png";
import linkedin from "../Images/Linkedin.png";


const Sidenav = () => { 
    return(
        <div className="sidenav">
            <div className="rightfixed">
                
            
                <div className="">
                    <div className="" >
                        <div className='minidiv minibm'><p4>PORTFOLIO 2022</p4></div>
                        <a href='https://dribbble.com/goutham-mummy-maya' target="_blank" rel="noopener noreferrer"><img className="minilogo" src={DribbleLogo} alt="My Dribble account" /></a>
                        <a href='https://www.instagram.com/pineapple_gradient/' target="_blank" rel="noopener noreferrer"><img className="minilogo" src={InstagramLogo} alt="My Instagram account" /></a>
                        <a href='https://www.behance.net/gouthamarace6a' target="_blank" rel="noopener noreferrer"><img className="minilogo " src={BehanceLogo} alt="My Behance account" /></a>
                        <a href='https://www.linkedin.com/in/goutham-krishna-a8a713167/' target="_blank" rel="noopener noreferrer"><img className="minilogo " src={linkedin} alt="My Behance account" /></a>
                    </div>
                </div>
            </div>
            
        </div>
    );
};
export default Sidenav;