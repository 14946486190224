
const Testimonialdata = [
    {
        company: "MAYASBAKEFACTORY",
        role:"FOUNDER",
        statement: "Branding should communicate our vaules to our customers. We are so satisfied by Gouthams work, from logo design to digially marking our brand."
    },
    {
        company: "ARAVALLY ENTERPRICES",
        role:"MANAGING DIRECTOR",
        statement: "Rebranding is not that easy. We had other designers, but Goutham's work actually made choose over all other options. I was able to see a world class design standards in his work"
    },
    {
        company: "FREELANCE",
        role:"DIGITAL MARKETER",
        statement: "Working with with Goutham was always so great. Could get all kind of solutions from him. whether it be branding, developing and more. He always motivates and push me forward."
    },
    
];



export default Testimonialdata;
