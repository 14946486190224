
import React from 'react';
//import Custombox3 from "/Users/gouthamkrishna/code/pineapplewebsite/mywebsite/src/Components/Elements/Custombox3.js";
import './Blogs.css';
import SwiperPlacer2 from './../Elements/Placer2.js';
//import Greek from "/Users/gouthamkrishna/code/pineapplewebsite/mywebsite/src/Components/Images/greek.png";
//<div className='inblock'><img className="inline-photo" src={Greek} alt="My Full Pic" /></div>


const Blogpage = () => { 
    return(
        <div className="page">
            <div className="DpTitle BoldHeadingStyle">
                READ MY BLOGS HERE
            </div>
            <div className="wfull">
                <div className="tocntr">
                    <SwiperPlacer2/>
                 </div>
            </div>
           
        </div>
    );
};
export default Blogpage;