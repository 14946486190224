import React from 'react';
import Blogitems from './../Data/data3';
import './Bloghome.css';
import { useNavigate } from "react-router-dom";


const Bloghome = () => { 
    const navigate = useNavigate();
    return(
        <div className="page">
            <div className="DpTitle BoldHeadingStyle">
                READ MY BLOGS HERE NOW
            </div>
            <div className="wfull">
                <div className="tocntr">
                <div className="floatrow"> {
                      Blogitems.map((item, index) => <div className=" blogslistitem" key={index} onClick={() => {
                        navigate(`/blogs/${item.id}`);
                      }}>
                      <div className="Bsh1">{item.name}</div>
                      <div className="Bshp1 ash">{item.description}, {item.date} </div>
                  </div>)
                     }
                </div></div>
            </div>
           
        </div>
    );
};
export default Bloghome;