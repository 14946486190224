import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import Blogitems from './../Data/data3';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";
import "./Swiper2.css";

// import required modules
import { FreeMode, Pagination } from "swiper";



function Swiper2(){
  const navigate = useNavigate();
return(
  <Swiper
        slidesPerView={2}
        spaceBetween={10}
        
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          10: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        }}
        
        modules={[FreeMode, Pagination]}
        freeMode={true}
        className="mySwiper"
      >
        
        {
                Blogitems.map( (item, i) => <SwiperSlide key={i}><div className="cbox3" >
                <div className="c3imgdiv GG" onClick={() => {
                navigate(`/blogs/${item.id}`);
              }}></div>
                <div className="c3boxtxt">{item.name}</div>
                <div className="c3boxsubtxt">{item.description}</div>
            </div></SwiperSlide> )
            }
      </Swiper>
      );

}
export default Swiper2;